












































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Layout from '@/layouts/LayoutDefault.vue';

import CheFolder from '@modules/chemical-risk/entities/CheFolder';
import ProductFolder from '@modules/chemical-risk/entities/ProductFolder';

import CheIdentificationFilter
  from '@modules/chemical-risk/components/form/CheIdentificationFilter.vue';

const cheModule = namespace('cheRisk');

@Component({
  components: {
    CheIdentificationFilter,
    Layout,
  },
})
export default class IdentificationView extends Vue {
  @cheModule.Getter('getActiveCheFolder')
  activeCheFolder!: CheFolder | null;

  @cheModule.Action('loadProductsFolder')
  loadProductsFolder: any;

  @cheModule.Action('deleteFolderProduct')
  deleteFolderProduct: any;

  products: ProductFolder[] = [];
  loading: boolean = false;
  total: number = 1;

  filters: any = {
    name: '',
    analyze: '',
    vendor: '',
    isNotCompleted: false,
    affectation: '',
    page: 1,
    order: '',
    orderBy: '',
    limit: 10,
  };

  tableProps: any = {
    border: true,
    stripe: true,
    defaultSort: { prop: 'label', order: 'ascending' },
  };

  paginationProps: any = {
    pageSizes: [10, 20, 50, 100],
    layout: 'prev, pager, next, jumper, ->, total, slot',
  };

  @Watch('filters', { immediate: true, deep: true })
  onFilterChange() {
    if (!this.loading) {
      this.load();
    }
  }

  handleAnalyze(product: any) {
    this.$router.push({ name: 'chemi-risks-identification-edit', params: { id: product.id } });
  }

  handleSortChange(payload: { type: string, sort: any }) {
    const { type, sort } = payload;
    if (type !== 'sort') {
      return;
    }

    if (sort.order !== null) {
      this.filters.order = sort.order === 'ascending' ? 'ASC' : 'DESC';
      this.filters.orderBy = sort.prop;
    } else {
      this.filters.order = '';
      this.filters.orderBy = '';
    }
  }

  async load() {
    this.loading = true;
    const data = await this.loadProductsFolder({ page: this.filters.page, filters: this.filters });

    this.products = data.items;
    this.total = data.meta.total;

    this.loading = false;
  }
}
