import { ProductStatus } from '@modules/chemical-risk/entities/Product';
import { ProductSheetStatus } from '@modules/chemical-risk/entities/ProductSheet';

export const ProductEnumName = new Map<number, string>([
  [ProductStatus.PRODUCT_PRODUCTION, 'Production'],
  [ProductStatus.PRODUCT_TRY, 'Essai'],
  [ProductStatus.PRODUCT_OUT, 'Sortie'],
]);

export const ProductSheetStatusEnumName = new Map<number, string>([
  [ProductSheetStatus.DRAFT, 'Brouillon'],
  [ProductSheetStatus.UNDER_INVESTIGATION, 'En cours d\'analyse'],
  [ProductSheetStatus.REJECT, 'Rejetée'],
  [ProductSheetStatus.VALIDATE_CONSISTENT, 'Etiquetage correct'],
  [ProductSheetStatus.VALIDATE_NOT_CONSISTENT, 'Etiquetage erroné'],
  [ProductSheetStatus.REVIEW_IMPOSSIBLE, 'Analyse critique irréalisable'],
]);

export const SensibilityEnumName = new Map<string, string>([
  ['all', 'Tous les sensibilisants'],
  ['(c)', 'Sensibilisant cutané'],
  ['(r)', 'Sensibilisant respiratoire'],
]);

export const CmrEnumName = new Map<string, string>([
  ['all', 'Tous les CMR'],
  ['(a)', 'CMR Avéré'],
]);
