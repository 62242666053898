






































import { Component } from 'vue-property-decorator';

import FilterComponent from '@/components/form/FilterComponent';
import StructureFilter from '@/components/form/StructureFilter.vue';

import { ProductSheetStatusEnumName } from '@modules/chemical-risk/types/enum';

@Component({
  components: { StructureFilter },
})
export default class CheIdentificationFilter extends FilterComponent {
  filters = {
    name: '',
    vendor: '',
    status: '',
    affectation: '',
    analyze: '',
    isNotCompleted: '',
  };

  productSheetStatusEnum = ProductSheetStatusEnumName;
}
